@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../css/app";

.IntroComponent {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.IntroHeader {
    font-size: 32px;
}

.IntroContent{
    padding-left: 20px;
}