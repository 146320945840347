@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.RichTextInputComponent{
    position:relative;
    font-size: $body-baseline;
    .RTEcontainer{
        width:100%;
        height:100%;
        display:flex;
        flex-direction: column;
        gap: 20px;
        .format-controls{
            flex: 0 0 auto;
            display:flex;
            flex-direction: row;
            justify-content: right;
            gap:10px;
            .format-buttons{
                flex: 0 0 auto;
                height: fit-content;
                background-color: $background-light;
                border-radius: 4px;
                display: flex;
                width:fit-content;
                button{
                    flex: 0 0 40px;
                    border:1px solid $stroke;
                    border-right:none;
                    width:40px;
                    height:40px;
                    padding: 8px;
                    color:$text-secondary-interactive;
                    &:first-child{
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                    &:last-child{
                        border-top-right-radius: 4px;
                        border-right:1px solid $stroke;
                        border-bottom-right-radius: 4px;
                    }
                    &.active{
                        color: $brand-brightorange;
                    background-color: $ui-white;
                    }
                    &:disabled{
                        background: $background-light;
                        border-color:$stroke-light;
                        color: $text-inactive;
                    }
                }
            }
            .format-size{
                flex: 0 0 120px;
            }
        }

        .editor-field{
            height:40px;
            flex: 0 0 auto;
            min-width: 0;
            .DraftEditor-root{
                height:100%;
                .DraftEditor-editorContainer{
                    height:100%;
                    .public-DraftEditor-content{
                        border: 1px solid $stroke;
                        border-radius: 4px;
                        padding:7px 12px;
                        color: $text-default;
                        overflow-y: auto;
                        height:100%;
                        div div:not(:last-child) .public-DraftStyleDefault-block > span{
                            background-image: url('../../../../assets/icons/ReturnArrow.svg');
                            background-repeat: no-repeat;
                            background-position: center right;
                            padding-right:24px;
                        }
                    }
                }
            }
            
            &.disabled{
                .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content{
                    color:$text-inactive;
                    border-color:$stroke-light;
                    background-color:$background-medium;
                    div div:not(:last-child) .public-DraftStyleDefault-block > span{
                        background-image:none;
                    }
                }
            }
        }
    }

    &.error{
        .RTEcontainer .editor-field {

            .DraftEditor-root .DraftEditor-editorContainer .public-DraftEditor-content{
                border-color:$ui-red;
            }
            .RTE_error{
                font-size:$micro;
                text-align: left;
                color:$ui-red;
                width:100%;
                display: block;
                margin-top: 0px;
                margin-bottom: -18px;

            }
        }
    }
        

    &.ctrls-right, &.ctrls-right-single{
        .RTEcontainer{
            flex-direction: row-reverse;
            gap: 10px;
            align-items: center;
            .format-controls{
                flex: 0 0 auto;
                display:flex;
                flex-direction: column;
                justify-content: right;
                gap:6px;
                .format-buttons{
                    flex: 0 0 40px;
                }
                .format-size{
                    flex: 0 0 40px;
                }
            }
            .editor-field{
                flex: 1 0 80px;
            }
        }
    }

    &.ctrls-right-single{
        .RTEcontainer{
            .format-controls{
                flex: 0 0 246px;
                display:flex;
                flex-direction: row;
                justify-content: right;
                gap:6px;
                .format-buttons{
                    flex: 0 0 120px;
                }
                .format-size{
                    flex: 0 0 120px;
                }
            }
        }
    }

    &.hasLabel{
        .editor-field {
            margin-top:32px;
        }
        &.ctrls-top .editor-field {
            margin-top:0px;
        }

        .LabelComponent{
            position:absolute;
            left:0px;
            top:0px;
        }
    }

}
