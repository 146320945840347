@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabOptionsComponent{   
   

}

.TabOptionsComponent.content-box.error  .Card.CardOptionsRegularComponent{
    
        border-color:$ui-red;
        border-width: 1px;
        border-style: solid;
    
}

.table_options_error{
    font-size:$micro;
    text-align: left;
    color:$ui-red;
    width:100%;
    display: block;
    margin-top: 0px;
    margin-bottom: -18px;

}

.CardOptionsRegularComponent{
    .form-row .field-option-order{
        flex: 0 0 150px;
    }

    .form-row .field-min-selects, .form-row .field-max-selects{
        flex: 0 0 120px;
    }
}

.CardOptionsRegularComponent, .CardOptionsAlternativeComponent{
    .OptionList{
        .column-code {
            align-items: flex-end;
            &.AppFormTableHeader{
                width:72px;
            }
            & > *{
                text-align:right;
                justify-content: flex-end;
            }
        }

        .column-label {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:auto;
            }
            & > *{
                max-width: 1200px;
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-image, .column-video {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:200px;
            }
            & > *{
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-fixed {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:70px;
            }
            & > *{
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-showlabel, .column-remove, .column-exclusive{
            align-items: center;
            &.AppFormTableHeader{
                width:112px;
            }
            & > *{
                text-align:center;
                justify-content: center;
            }
        }

        .column-empty {
            width:302px;
        }
    }
}

.CardOptionsCustomComponent{
    .form-row:first-child{
        margin-bottom: 15px;
    }

    .form-row .field-custom-code{
        flex: 0 0 40px;
        height:40px;
        display:flex;
        align-items: center;
        flex-direction: row-reverse;
        div{
            font-size:$micro;
            color:$text-default;
            text-align:right;
        }
        margin-left:16px;
    }

    .form-row .field-custom-label{
        flex: 0 0 300px;
    }

    .form-row .field-custom-maxlength{
        flex: 0 0 100px;
    }

    .form-row .field-custom-fieldamount{
        flex: 0 0 80px;
    }

    .form-row .field-custom-exclusive{
        
    }
}

.EmptyOptionListInfoTitle {
	color: $grey-500;
	font-weight: bold;
	font-size: $body-baseline;
	text-align: center	
}

.EmptyOptionListInfoText {
	color: $grey-500;	
	font-size: $caption;
	text-align: center
}

.span-box{
	border: 1px solid $grey-500;
	padding: 2px;
	border-radius: 4px;
}