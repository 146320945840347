@import '~@ecosystem/ui-lib/core/styles/base/all';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/all';
@import "app";
@import "layout";

body{
    font-family: $font-primary;
    font-size: $body-baseline;
    -moz-osx-font-smoothing: grayscale;
    background-color: $background-light;
    color: $text-default;
    max-width:1980px;
}


@media screen and (min-width: 1980px) {
    body{
        margin: 0 auto;
        border-left: 1px solid $stroke-light; 
        border-right: 1px solid $stroke-light; 
    }
}

.scrollbox{
    scrollbar-color: $grey-400 $grey-100;
}



.icon{
    width:24px;
    height:24px;
    &.small{
        width:16px;
        height:16px;
    }

    &.brand{
        color:$brand-brightorange;
    }

    &.grey{
    color:$text-lighter;
    }

    &.clickable{
    cursor:pointer;
    }
}




