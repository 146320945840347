@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.SwitchComponent{
    width: 46px;
    height: 26px;
    border-radius: 20px;
    background-color: $background-dark;
    position:relative;
    .switch-dot{
        position:absolute;
        border-radius: 20px;
        left: 3px;
        top: 50%;
        transform: translate(0, -50%);
        transition: left 100ms linear;
        width:20px;
        height:20px;
        background:$ui-white;
    }
    &.isOn{
        background:$brand-brightorange;
        .switch-dot{
            left: 23px;
            transition: left 100ms linear;
        
        }
    }

}

.SwitchComponentEnabled {
    cursor: pointer;
}

.SwitchComponentDisabled {
    cursor: not-allowed;
}