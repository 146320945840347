@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.RangeSliderComponent{
    .LabelDisplay{
        display:flex;
        justify-content: space-between;
        align-items: flex-end;
        .RangeDisplay{
            font-size: $caption;
            color:$text-inactive;
        }
    }
    &.disabled{
        .RangeDisplay{
            display:none;
        }
    }
    .RangeSlider{
        height:40px;
        width:100%;
        .SliderThumb{
            background-color: $brand-brightorange;
            border-radius: 10px;
            width:16px;
            height:16px;
            margin-top:12px;
            cursor:pointer;
        }
        .SliderMark{
            height:16px;
            width:1px;
            display:flex;
            flex-direction: column;
            align-items: center;
            margin-left: 7px;
            bottom:0px;
            
            span{
                flex:0 0 4px;
                background-color:$background-medium;
                width:1px;
            }

            div{
                flex:0 0 10px;
                font-size: $micro;
                color:$text-inactive;
              
            }
        }
        .SliderTrack{
            
            display:flex;
            align-items: center;
            height:40px;
            padding-left:6px;
            padding-right:6px;
            .TrackLine{ 
                flex: 1 0 100%;
                height:4px;
                border-radius: 3px;
                background-color: $background-medium;
            }
            &.SliderTrack-1 .TrackLine{
                background-color: $brand-brightorange;
            }
        }
        &.disabled{
            .SliderThumb{
                background-color: $background-dark;
            }
            .SliderTrack{
                &.SliderTrack-1 .TrackLine{
                    background-color: $background-dark;
                }
            }
            .SliderMark{    
                span{
                    background-color:$background-medium;
                }
                div{
                    color:$background-medium;
                }
            }

        }

    }

    &.RangeSlider-none{
        .SliderThumb{
            display:none;
        }
    }

    &.RangeSlider-left{
        .RangeSlider{
            .SliderTrack{
                &.SliderTrack-0 .TrackLine{
                    background-color: $brand-brightorange;
                }
                &.SliderTrack-1 .TrackLine{
                    background-color: $background-dark;
                }
            }
            &.disabled{
                .SliderTrack{
                    &.SliderTrack-0 .TrackLine{
                        background-color: $background-dark;
                    }
                    &.SliderTrack-1 .TrackLine{
                        background-color: $background-medium;
                    }
                }
            }

        }
    }

}
