@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.DatePickerComponent {
	input {
		background-color: #fff;
		border: 1px solid $grey-400;
		border-radius: 4px;
		color: $grey-800;
		display: flex;
		font-size: 16px;
		height: 40px;
		padding-left: 12px;
		padding-right: 16px;
		position: relative;
		width: 100%;
	}

	input[type="text"]:disabled {
		background-color: $background-medium;		
	}

	.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected  {
		background-color: $brand-brightorange;
	}	

	.react-datepicker__close-icon::after {
		background-color: transparent !important;
		content: url('/app/assets/icons/clear.svg') !important;
		outline: none;
	}
}
