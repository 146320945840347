@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.new-folder-container
{ 
	border-top: 1px solid #dadde3;
	margin-left: 10px;

	.new-folder-input-container {
		display: none;
		.Input__container {			
			padding-top: 0px;
		}		
	}

	.new-folder-input-container.visible {
		display: flex;
		margin-top: 10px;
		margin-right: 10px;
	}

	.add-new-folder-button {	
		width: 100%;
		display: flex;
		flex-direction: row-reverse;    
		padding: 16px 0px 16px 0px;		
	}

	.add-new-folder-button.hidden {		
		display: none;		
	}

	.new-folder-input {		
		margin-left: 10px;		
	}

	.new-folder-input-buttons {
		
		display: flex;
		flex-direction: row-reverse;    
		margin-top: 8px;
	}

}
