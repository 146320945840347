@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';


.TabAdHocSurveyOverviewComponent {
	.column-name {
		align-items: flex-start;
        width: 350px;
	}

	.column-startDate {
		align-items: flex-start;
	}

	.column-endDate {
		align-items: flex-start;
	}

	.column-enabled {
		align-items: flex-start;
	}
    
    .column-sapleId {
		align-items: flex-start;
	}

    .column-nqSurveyId {
		align-items: flex-start;
	}	

	.Table__ColumnHeader.AppFormTableHeader.column-edit {
		width: 30px
	}
	
	.edit-icon {			
		cursor: pointer;
		color: $brand-brightorange;
	}

    .EmptyAdHocOverviewListInfoTitle {
        color: $grey-500;
        font-weight: bold;
        font-size: $body-baseline;
        text-align: center;
        padding-top: 15px;
    }
}





