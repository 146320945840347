@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.ConditionLogicsComponent {
	span.orange {
		color: $yellow-800;
	}
	.QuestionListContainer {
		height: 400px;		
		overflow: auto;
	}

	.QuestionListContainerScrollbox {
		margin-top: 10px;
	}	
    
	.ConditionQuestionList {
		.column-question-condition{
			width: 43%;			
		}

		.column-valid-answer-condition {
			width: 42%;
			//white-space: nowrap;
		}

		.column-negate-condition {
			min-width: 100px;				
		}

		.column-remove-condition {
			min-width: 100px;					
			text-align:center;
			white-space: nowrap;
		}

		td:nth-child(3) {
			display: flex;
			vertical-align: top;
			align-items: center;
  			justify-content: center;
		}

		td:nth-child(4) {			
			vertical-align: top;			
		}

		.GenericCell.column-negate {
			margin-left: -35px;
		}

		.GenericCell.column-remove {
			margin-left: -15px;
		}
	}

	.QuestionItemColumn {
		cursor: pointer;
	}	
}