@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.InlineTextEditorComponent {
	.inline-text-editor-container {
		display: flex;
	}

	.inline-text-buttons {
		display: flex;
		padding-top: 8px
	}
}