@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabIndepthQuestionsComponent{
    .column-code{
		width: 20%;
	}

	.column-type {
		width: 14%;
		white-space: nowrap;
	}

	.column-question-text {
		width: 47%;
	}

	.column-question-enforce {
		width: 9%;		
		margin: 0px auto;

		.GenericCell__primary {
			width: auto !important;
		}
	}

	.column-remove {
		width: 7%;
		margin: 0px auto;

		.GenericCell__primary {
			width: auto !important;
		}
	}

	.EmptyQuestionListInfoTitle {
		color: $grey-500;
		font-weight: bold;
		font-size: $body-baseline;
		text-align: center;
		padding-top: 15px;
	}
	
	.EmptyQuestionListInfoText {
		color: $grey-500;	
		font-size: $caption;
		text-align: center
	}
	
	.span-box{
		border: 1px solid $grey-500;
		padding: 2px;
		border-radius: 4px;
	}
}