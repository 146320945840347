@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../css/app";

.AppHeaderComponent{
    flex: 0 1 auto;
    width:100%;
    height:78px;
    border-bottom: 1px $stroke-light solid;
    display:flex;
    flex-direction: row;
    font-size: $caption;
}
