@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.ConditionComponent {
	span.orange {
		color: $yellow-800;
	}

	.condition-enabled {
		//display: inline-flex;;
	}

	.condition-enabled-switch {
		margin-left: 10px;
	}

	.condition-header {
		margin: 0 0 20px 0 !important;
	}

	.line-header {
		border-top: 1px solid $grey-300;
		border-bottom: 0px;
		width: 200%;
		margin-left: -20px;
		margin-right: -20px;
	}

	
}