@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.TabBarComponent{
    font-size:$body-baseline;//$caption;
    .tab-item{
        margin-right:24px;
        border-bottom: 2px solid transparent;
        list-style-type: none;
        display:inline-block;
        .tab-link{
            text-decoration: none;
            color:$text-secondary-interactive;
            line-height: 1.5;
            padding: 5px 0px;
            display:flex;
            flex-direction: row;
            gap:5px;
            align-items: center;
            &:focus{
                box-shadow: none;
            }
            
        }
        &.active{
            border-color:$brand-darkorange;
            &.error{
                border-color:$ui-red;
            }
            .tab-link{
                color:$brand-darkorange;
            }
        }
        &.error{
            .tab-link{
                color: $ui-red;
            }
        }
        &:hover,&.error:hover{
            border-color:$brand-brightorange;
            .tab-link{
                color:$brand-darkorange;
            }
        }
      
    }
    .SpacerComponent{
        display:inline-block;
    }

	.submenu-down-arrow {	
		color: $grey-500;
		margin-top: -8px;
		padding-top: 10px;
		
	}

	.dropdown-parent {
		position: relative;
  		display: inline-block;
	}
}