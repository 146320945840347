@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";
.InDepthTestSurveyDemoSetComponent {
	min-width: 250px;	
	.DropDownMultiSelectFilter-selected-list {
		margin-top: 10px;
		min-height: 300px;
	}

	.demo-box {
		padding-bottom: 15px;
	}

	:focus {
		box-shadow: 0 0 0 0;
	}

	.css-1pahdxg-control {
		border-color: $yellow-200 !important;
		box-shadow: 0 0 0 1px $yellow-200 !important;
	}

	.css-1pahdxg-control:hover {
		border-color: $yellow-200 !important;
	}	
}