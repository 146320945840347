@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.SubSurveyTable {
	width: 1200px;
	.Table{
        table-layout: fixed;
        .AppFormTableHeader{
            padding:8px 16px;
            .Table__ColumnHeader__label{
                margin:0;
            }
            .LabelComponent{
                margin:0px;
                height:18px;
            }
        }

        .Table__ColumnHeader, .GenericCell{
            & > *{
                width:100%;
            }
        }

        .Table__Body{
            td{
                padding:18px 16px; 
            }
        }

        .ButtonIcon{
            margin:0;
        }
		
		.Table__ColumnHeader.AppFormTableHeader.column-name {
			width: 200px;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-nqtemplate-id {
			width: 100px;
		}		
		.Table__ColumnHeader.AppFormTableHeader.column-start-date {
			width: 100px;			
		}	
		.Table__ColumnHeader.AppFormTableHeader.column-end-date {
			width: 100px;			
		}		
		.Table__ColumnHeader.AppFormTableHeader.column-edit {
			width: 30px
		}
		.Table__ColumnHeader.AppFormTableHeader.column-sequence {
			width: 80px
		}
		.Table__ColumnHeader.AppFormTableHeader.column-time-interval {
			width: 90px
		}
		.Table__ColumnHeader.AppFormTableHeader.column-media-types {
			width: 100px
		}
		

		.column-name {			
			.subSurvey-name {
				font-weight: bold;
			}
		}	

		.edit-icon {			
			cursor: pointer;
			color: $brand-brightorange;
		}
    }
}
