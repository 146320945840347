@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabQuestionsComponent{
	
	.Table td, .Table th {
		padding: 12px;
	}

    .column-code{
		width: 20%;
	}

	.column-type {
		width: 15%;
		white-space: nowrap;
	}

	.column-question-text {
		width: 52%;
	}

	.column-condition {
		width: 50px;
		margin: -5px;

		.GenericCell__primary {
			width: auto !important;
		}
	}

	.column-remove {
		width: 50px;
		margin-left: -10px;

		.GenericCell__primary {
			width: auto !important;
		}
	}

	.EmptyQuestionListInfoTitle {
		color: $grey-500;
		font-weight: bold;
		font-size: $body-baseline;
		text-align: center;
		padding-top: 15px;
	}
	
	.EmptyQuestionListInfoText {
		color: $grey-500;	
		font-size: $caption;
		text-align: center
	}
	
	.span-box{
		border: 1px solid $grey-500;
		padding: 2px;
		border-radius: 4px;
	}

	.column-condition {
		.Icon {			
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			display: inline-block;
		}
	}

	.condition-counter-badge {
		border-color: $brand-brightorange;
		border-style: solid;
		border-width: 1px;
		color: $brand-brightorange;
		background-color: #ffffff
	}	
}