@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.NavigationComponent{
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: bottom;
    height:100%;
    padding: 0px 20px;

    .main{
        color: $text-secondary-interactive;
        flex:0 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;   
        font-size:inherit;

        .tab-item{
            cursor: pointer;
            display: flex;
            font-weight:$font-weight-heavy;
            height:100%;
            border-width:4px;
            .tab-link{
                padding-bottom:1px;
            }
            
            &:not(.active):hover{
                background-color:$background-medium;
            }
        }
    }

    .secondary{
        @extend .main;
        //flex-direction: row-reverse;

        .navi-item{
            margin: 0px 0px 0px 20px;
            display:flex;
            flex-direction: row;
            gap: 12px;
            

            &.user-account{
                color: $text-lighter;
                font-weight:400;
                cursor: auto;
            }

            &:not(.selected):hover{
                background-color: transparent ;
            }


        }
    }

    
}