@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.SpacerComponent{
    flex: 0 0 auto;
    &.line{
        width:1px;
        height:24px;
        margin-left:0px;
        margin-right:0px;
        background-color: $stroke;
    }
}