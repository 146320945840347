@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.InputComponent{
    height:40px;
    font-size:$body-baseline;
    color:$text-default;
    border-radius: 4px;
    border:1px solid $stroke;
    background-color: $ui-white;
    position:relative;
    min-width:80px;
    width:100%;

    svg, img{
        position:absolute;
        color:$text-lighter;
        top: 50%;
        left: 8px;
        transform: translate(0, -50%);
        
        & + input{
            padding-left: 32px;
            padding-right: 16px;
        }
    }

    input{
        font-size: inherit;
        color: $text-default;
        border:none;
        background-color: transparent;
        width:100%;
        height:100%;
        min-width: 0;
        padding:8px 32px 8px 16px;

        & + svg, + img{
            right: 8px;
            left: auto;
            transform: translate(0, -50%);
        }
    
        &:first-child:last-child{
            padding:8px 16px 8px 16px;
        }
    }
}

.InputInlineComponent{

    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height:40px;


    .value-display{
        white-space: nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        padding:1px 1px 1px 0px;
        user-select: none;
        flex: 0 1 auto;
    }

    /*.close-icon{
        cursor: pointer;
    }*/

    label{
        display:none;
    }
    
    .edit-icon{
        flex: 0 0 24px;
        margin-left:8px;
        min-width:24px;
        cursor: pointer;
        color: $brand-brightorange;
    }

	.edit-icon.disabled{        
        color: $grey-500;
		cursor: auto;
    }

    .edit-icon.showOnHover{
        opacity: 0;
        visibility:hidden;
        transition: opacity 0ms linear 0s;
    }


    &:hover .edit-icon.showOnHover{
        opacity: 1;
        transition: opacity 100ms linear 1000ms;
        animation: fade-in-icon 1ms;
        animation-delay: 1000ms;
        animation-fill-mode: forwards;
    }

    &.editmode > div{
        width:100%;
    }

    .Input__clearIcon{
        padding: 0 0;
        margin: auto 0;
    }

	.inline-text-buttons {
		width: auto !important;
		display: flex;
	}

}


@keyframes fade-in-icon {
    from {
      visibility: hidden;
    }
    to {
        visibility: visible;
    }
}