@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.AppTitleComponent{
    flex: 0 0 auto;
    width:360px;
    height:100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .apptitle{
        margin-left:32px;
        flex: 0 1 auto;
    }

    .environment{
        flex: 0 1 auto;
        cursor: pointer;
        padding: 10px 0px 10px 6px;
        display: flex;
        align-items:center;
        height:37px;
        
        
        .Select__control, .Select__control--is-focused, .Select__control:hover{
            font-weight: $font-weight-heavy;    
            color: $text-secondary-interactive;
            border:none !important;
            background-color:transparent ;
            
        }

		.panelName {
			font-weight: $font-weight-heavy;    
            color: $text-secondary-interactive;
            border:none !important;
            background-color:transparent ;
		}
    }
}

