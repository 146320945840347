@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.SideListComponent{
    .SideListItemComponent{
        padding: 0px 20px 0px 32px;
        font-size:$caption;
        color:$text-inactive;
        display:flex;
        flex-direction:row;
        align-items: center;
        cursor: pointer;
        &.selected{
            border-left:3px solid $brand-brightorange;
            padding-left:29px;
        }
        .left{
            flex: 1 0 100px;
            padding:10px 0px; 
            overflow:hidden;
            .label{
                font-weight:bold;
                height: 23px;
                display:flex;
                align-items: center;
                color:$text-secondary-interactive;    
                overflow:hidden;
            }
            .top, .bottom{
                min-width: 0;
                font-size:$micro;
                height:14px;
                display:flex;
                align-items: center;
                visibility: hidden;
                overflow:hidden;
            }
            .text-overflow{
                padding: 1px 0px;            
                vertical-align: center;
                overflow:hidden;

                text-overflow:ellipsis;
                white-space: nowrap;
                div{
                    float:left;
                    margin-right:15px;
                    display:flex;
                    align-items: center;
                    gap: 2px;
                    vertical-align:middle;
                }
            }
        }
        .right{
            flex: 1 1 fit-content;
        }
        &.selected .label{
            color:$brand-brightorange;
            height:23px;
        
        }

    }

    .SideListItemComponent:hover, .SideListItemComponent.selected{
        background-color: $background-medium;
    }

    .SideListItemComponent:hover .top, .SideListItemComponent.selected .top, .SideListItemComponent:hover .bottom, .SideListItemComponent.selected .bottom{
        visibility:visible;   
    }


    .SideListFolderComponent{
        height:auto;

        .folder-head{
            border-top: 1px solid $stroke-light;
            height:50px;
            display:flex;
            flex-direction: row;
            align-items: center;
            color: $text-secondary-interactive;
            width:100%;

            .toggle{
                flex: 0 0 24px;
                margin-right:8px
            }

            .InputInlineComponent{
                flex: 1 0 100px;
                min-width:0px;
                margin-right:8px;
                .value-display{
                    font-weight: $font-weight-heavy;
                    font-size: $body-baseline;
                }
            }

            .bin-icon{
                flex: 0 0 24px;
                color:$brand-brightorange;
                margin-right:8px;
                opacity: 0;
                visibility:hidden;
                cursor: pointer;
                transition: opacity 0ms linear 0s;
                
            }

            &:hover .bin-icon{
                opacity: 1;
                transition: opacity 100ms linear 100ms;
                animation: fade-in-icon 1ms;
                animation-delay: 1000ms;
                animation-fill-mode: forwards;
            }

            &:hover .InputInlineComponent .edit-icon{
                opacity: 1;
                transition: opacity 100ms linear 1000ms;
                animation: fade-in-icon 1ms;
                animation-delay: 1000ms;
                animation-fill-mode: forwards;
            }
        }

        .collapse-area {
            transition: height 300ms linear;
        }

		.bin-icon.disabled{        
			color: $grey-500;
			cursor: auto;
		}
    }


}

@keyframes fade-in-icon {
    from {
      visibility: hidden;
    }
    to {
        visibility: visible;
    }
}