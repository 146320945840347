@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.adHocSurveyForm .GlobalFieldLineComponent.form-row{
    .field-netquest-schema {
        display: flex;
    }
}

