@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.MediaUrlHandlerComponent{
    .file-preview-area{
        width:100%;
        height:100%;
        border-radius: 4px;
        border:1px dashed $stroke;
        height:80px;
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding:5px;
        .instruction-label{
            color:$text-inactive;
            font-size: $micro;
            flex: 1 0 auto;
            text-align:center;
            margin:5px;
        }
        .ButtonComponent{
            flex: 0 0 auto;
            margin:5px;
        }
        
    }
    &.disabled .file-preview-area{
        background-color: $background-medium;
        border-color: $stroke;
    }

    &.error{
        .file-preview-area{
            border-color:$ui-red;
        }
        .MediaUrlHandler_error:not(:empty){
            font-size:$micro;
            text-align: left;
            color:$ui-red;
            width:100%;
            display: block;
            margin-top: 0px;
            margin-bottom: -18px;

        }
    }

	.modal-title {
		font-size: $h4;
		padding-bottom: 20px;
	}

	.button-upload-actions {
		width: 64px;
	}
}
    