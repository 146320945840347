@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";



.LabelComponent{
    display:flex;
    align-items: center;
    margin-bottom:8px;
    height:24px;
    label{
        display:inline-block;
        margin-bottom:0px;
        & + *{
            font-size:0px;
        }
    }
    .Icon{
        //cursor: help;
        margin: 0px 0px 0px 4px;
    }

}