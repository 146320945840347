@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.ButtonComponent{
    cursor: pointer;
    &.separated-icon{
        position:relative;
        font-size:0;
        svg, img{
            pointer-events: none;
            position:absolute;
            left:16px;
            width:24px;
            height:24px;
            top: 50%;
            transform: translate(0, -50%);
            color: $ui-white;
        }

        .Button{
            /*height:40px;
            min-width:40px;*/
            padding-left:48px;
            padding: 7px 15px 7px 47px;
            &.Button--secondary{
                & + svg, + img{
                    color: $brand-darkorange;
                } 
            }
            &.Button--simple{
                padding: 8px 16px 8px 48px;
                & + svg, + img{
                    color: $brand-brightorange;
                } 
            }
            &:disabled{
                //padding: 8px 16px 8px 48px;
                & + svg, + img{
                    color: $text-inactive;
                }
            }
            &.Button--small{
                padding: 4px 8px 4px 28px;
                & + svg, + img{
                    width:16px;
                    height:16px;
                    left:8px;
                } 
            }
            &:active.Button--secondary{
                & + svg, + img{
                    color: $ui-white;
                }
            }   
        }        
    }


}

.ButtonGroupComponent{
    display:flex;
    gap:0px;
    .ButtonComponent{
        .Button{
            border-radius: 0px;
            background-color: $background-light;
            border-color:$stroke;
            color:$text-default;
            border-right: none;
            & + svg, + img{
                color:$text-default;
            }
        }
        &:first-child .Button{
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        &:last-child .Button{
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-right: 1px solid $stroke;
        }
        &.isActive .Button{
            background-color: $ui-white;
            color : $brand-brightorange;
            & + svg, + img{
                color:$brand-brightorange;
            }
        }
        
    }

}
