@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabGridComponent {
	.column-grid-code {
		width: 25%;
	}
	.column-grid-question-text {
		width: 68%;
	}
	.column-grid-remove {
		width: 7%;
	}
	
	.EmptyQuestionListInfoTitle {
		color: $grey-500;
		font-weight: bold;
		font-size: $body-baseline;
		text-align: center;
		padding-top: 15px;
	}

	.EmptyQuestionListInfoText {
		color: $grey-500;	
		font-size: $caption;
		text-align: center
	}

	.span-box{
		border: 1px solid $grey-500;
		padding: 2px;
		border-radius: 4px;
	}

	.Table.Draggable.QuestionList {
		width: 100%;
	}

	.RichTextInputComponent .editor-field {
		cursor: text;
	}
}