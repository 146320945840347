@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.DemographicAnswersComponent {
    .field-demo-answer {
        display: flex;
		gap: 10px;		
    }
    
    .demo-labels {
        width: 330px;
    }

    .field-demo-answer {
        .Input__wrapper {
            width: 330px;
        }
    }
}