@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";
.overview-filters-component {
	margin-bottom: 20px;

	.form-row {
		grid-gap: 0px !important;
	}

	.field-overview-date-picker {
		width: 150px;
	}

	.field-overview-date {
		margin-left: -25px;
		.Button {
			padding-top: 7px;    
    		padding-bottom: 7px
		}
	}

	.field-overview-filter {
		width: 420px;
		flex-grow: 1 !important;
	}
}