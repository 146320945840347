@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.TabDemoSetQuestionsComponent{
	
	.Table td, .Table th {
		padding: 12px;
	}

    .column-code{
		width: 30%;
	}
	
	.column-question-text {
		width: 60%;
	}	

	.column-remove {
		width: 50px;
		margin-left: -10px;

		.GenericCell__primary {
			width: auto !important;
		}
	}

	.EmptyQuestionListInfoTitle {
		color: $grey-500;
		font-weight: bold;
		font-size: $body-baseline;
		text-align: center;
		padding-top: 15px;
	}
	
	.EmptyQuestionListInfoText {
		color: $grey-500;	
		font-size: $caption;
		text-align: center
	}
	
	.span-box{
		border: 1px solid $grey-500;
		padding: 2px;
		border-radius: 4px;
	}

	
}