@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

@mixin invertBadge ($invert, $primeColor, $secondaryColor){
    @if $invert {
        color: $primeColor;
        background-color: transparent;
        border: 1px solid $primeColor;
    } @else {
        color: $secondaryColor;
        background-color: $primeColor;
        border: 1px solid $primeColor;
    }
}

.StatusBadgeComponent{
    
    /*font-size: $micro;
    font-weight:$font-weight-bold;
    line-height:20px;
    padding: 0px 6px;
    border-radius: 100px;*/
    display:inline-block;
    @include invertBadge( true, $background-darkest, $ui-white );
        
        
    &.badge-filled{
        @include invertBadge( false, $background-darkest, $ui-white );
    }
    
    &.badge-brand{
        @include invertBadge( true, $brand-brightorange, $ui-white );
    }
    
    &.badge-brand.badge-filled{
        @include invertBadge( false, $brand-brightorange, $ui-white );
    }
    
    &.badge-red{
        @include invertBadge( true, $ui-red, $ui-white );
    }
    
    &.badge-red.badge-filled{
        @include invertBadge( false, $ui-red, $ui-white );
    }
}