@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.NqTemplateTable {

	.Table{
        table-layout: fixed;
        .AppFormTableHeader{
            padding:8px 16px;
            .Table__ColumnHeader__label{
                margin:0;
            }
            .LabelComponent{
                margin:0px;
                height:18px;
            }
        }

        .Table__ColumnHeader, .GenericCell{
            & > *{
                width:100%;
            }
        }

        .Table__Body{
            td{
                padding:18px 16px; 
            }
        }

        .ButtonIcon{
            margin:0;
        }

		
		.Table__ColumnHeader.AppFormTableHeader.column-name {
			width: 25%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-template-id {
			width: 5%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-version {
			width: 5%;			
		}
		.Table__ColumnHeader.AppFormTableHeader.column-survey-phase {
			width: 10%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-survey-type {
			width: 15%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-start-date {
			width: 10%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-active {
			width: 5%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-remarks {
			width: 20%;
		}
		.Table__ColumnHeader.AppFormTableHeader.column-edit {
			width: 30px
		}
		.Table__ColumnHeader.AppFormTableHeader.column-delete {
			width: 5%;
		}

		.column-name {			
			.template-name {
				font-weight: bold;
			} 
			.modified-date {
				color:$grey-500;
				font-size:$micro;
			}
		}

		.column-version {
			width: 5%;
			margin: auto;
		}

		.edit-icon {			
			cursor: pointer;
			color: $brand-brightorange;
		}
    }
}
