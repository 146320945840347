@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';

.AppFormComponent.TestSurveyComponent {
	.createSurveyHeader {
		font-size: 24px;
		//color: $brand-darkorange;
	}

	.form-row-left.align-top {
		align-items: flex-start !important;
	}


	.float-right {
		float: right;
	}

	.form-row.demo-sets-wrapper {
		overflow-x: auto;
		overflow-y: hidden;
	}
}