@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabOptionConditionsComponent{
	.optionCondition {
		width: fit-content;
		min-width: fit-content;
		display: inline-block;
		padding-left: 5px;
		padding-right: 5px;
		.Select__menu {			
			width: 800px;
			.Select__menu-list {			
				.Select__option {					
					white-space: nowrap;
				}
			}
		}
	}
	.Select__control {
		min-width: fit-content;
	}

	.scrollable-content {
		height: calc(100vh - 370px);
    	overflow: auto;
	}

	.option-condition-applied {
		color: $brand-brightorange;
	}
}