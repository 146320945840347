@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabScaleComponent{
    .form-row{
        .field-min-value{
            flex: 0 0 100px;
        }

        .field-pointslider{
            flex: 0 0 400px;
        }
    }
}