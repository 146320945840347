@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.FiltersComponent.form-row {
    .questions-selection{
        min-width: 95%;
        max-width: 1000px;
    }

    .align-top {
        align-items: flex-start;
    }
}