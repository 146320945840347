@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../css/app";

.last-data-import {
	margin-bottom: 20px;
}

.downloadResponsesHeader {
	font-size: 24px;
}