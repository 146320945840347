@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabLoopComponent{
    .form-row .field-loop-order{
        flex: 0 0 150px;
    }
    .ItemList{
        .column-code {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:100px;
            }
            & > *{
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-label {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:auto;
            }
            & > *{
                max-width: 500px;
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-image {
            align-items: flex-start;
            &.AppFormTableHeader{
                width:232px;
            }
            & > *{
                text-align:left;
                justify-content: flex-start;
            }
        }

        .column-showlabel, .column-remove, .column-exclusive{
            align-items: center;
            &.AppFormTableHeader{
                width:112px;
            }
            & > *{
                text-align:center;
                justify-content: center;
            }
        }

        .column-empty {
            width:302px;
        }
    }
    


}
