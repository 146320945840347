@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";




.AppFormComponent{

    .Input__wrapper, .Select__control {
        height: 40px;
        padding-right: 0px;
        .Input__field, .Select__single-value{
            color: $text-default;
        }
        .Input__container + *{
            margin-right:16px;
        }
        .Input__field:disabled{
            color:$text-inactive;
        }
        &.Select__control--is-disabled{
            background-color: $background-medium;
            border-color: $stroke;
            .Select__single-value{
                color: $text-inactive;
            }
            .Select__indicator svg{
                fill: $text-inactive;
            }
        }
        & + .Input__error:not(:empty) {
            font-size: $micro;
            margin-top: 0px;
            height:18px;
            margin-bottom: -18px;
        }

    }

    .Card{
        padding: 24px;
        margin-bottom: 20px;
    }

    .tab-bar{
        border-bottom:1px solid $stroke-light;
        display: flex;
        align-items:center;
        justify-content: space-between;
        margin-bottom:20px;

        .TabbedNav{
            flex: 1 0 auto;
            .TabbedNav__tab{
                padding: 0px 0px 2px 0px;
                
                &:hover{
                    padding: 0px 0px 2px 0px;
                }
                .TabbedNav__tab__link{
                    padding: 5px 0px;
                    display:block;
                }
            }
        }

        .duplicate-tab{
            flex:0 0 24px;
            color:$brand-brightorange;
        }
    }

    .form-head, .content-box{
        margin-bottom:30px;
    }

    .form-row{
        margin: 0 0 30px 0;
        display: flex;
        gap: 50px;
        &:last-child{
            margin-bottom:0px;
        }
        .form-row-left, .form-row-right, .form-row-50{
            flex: 1 0 auto;
            display:flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap:30px;
            > * {
                flex: 0 0 auto;
            }

            > * > .default-height {
                height:40px;
                display:flex;
                align-items: center;
            }

            > * > .centered {
                text-align:center;
                display:flex;
                justify-content: center;
                
            }
        }

        .form-row-50{
            display: flex;
            flex-direction: column;
            gap: 20px;
            min-width: 300px;
            flex: 0 1 50%;
            justify-content: flex-start;
            > * {
                width:100%;
                flex: 0 0 auto;
            }
            
        }


        .form-row-right{
            justify-content: flex-end;
        }

        
       
    }

    .Table{
        table-layout: fixed;
        .AppFormTableHeader{
            padding:8px 16px;
            .Table__ColumnHeader__label{
                margin:0;
            }
            .LabelComponent{
                margin:0px;
                height:18px;
            }
        }

        .Table__ColumnHeader, .GenericCell{
            & > *{
                width:100%;
            }
        }

        .Table__Body{
            td{
                padding:18px 16px; 
            }
        }

        .ButtonIcon{
            margin:0;
        }
    }
	
	span.orange {
		color: $yellow-800;
	}
}