@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.TemplateFormComponent.form-row{
   
	.field-nq-template-name {
		width: 500px;
	}
    
	.field-nq-template-remarks {
		width: 890px;
	}

}