@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.MediaTypeForm .GlobalFieldLineComponent.form-row{
   
    .field-mediaType-name {
        width: 400px;
    }

    .field-mediaType-id {        
        .Input__wrapper {
            width: 100px !important;
            min-width: 100px !important;
        }
    }

    .field-mediaType-sort-order {        
        .Input__wrapper {
            width: 100px !important;
            min-width: 100px !important;
        }
    }

    .field-survey-startTime {        
        .Input__wrapper {
            width: 170px !important;
            min-width: 170px !important;
        }
    }

}