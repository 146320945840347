@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabQuestionComponent{
    .question-video-player {
		margin-top: 30px;
	}

	.field-image {
		flex: 1 !important;
		min-width: 222px !important;
	}

	.field-video {
		flex: 1 !important;
		min-width: 222px !important;
	}

	.field-video-desc {
		height: 100%;
		width: 500px;
	}
	
}