@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.ChannelForm .GlobalFieldLineComponent.form-row {
    .field-channel-name {
        width: 400px;
    }

    .field-channel-sort-order {        
        .Input__wrapper {
            width: 100px !important;
            min-width: 100px !important;
        }
    }

}