@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.multi-freetext-component {
	.freetext-list {		
		.freetext-item{
			border-style: solid;
			border-width: 1px;
			border-color: $grey-400;
			padding: 6px;
			border-top-width: 0px;
			display: flex; 
			.freetext-item-remove {
				margin-left: auto;
			}			
		}
		.freetext-item:last-child {
			border-bottom-left-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}

	.Input__wrapper {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
}
  
 