@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../css/app";

.ReportDownloadForm {
	.downloadReport {
		font-size: 24px;
	}

	.last-data-import {
		margin-bottom: 20px;
	}
}