@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabPresentationComponent{
    
    .form-row .field-presentation-orientation{
        flex: 0 0 150px;
    }
    .form-row .field-presentation-numberfield{
        flex: 0 0 250px;
    }

	.circleSliderButton svg {
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			-o-transform: rotate(180deg);
			transform: rotate(180deg);
			display: inline-block;
			margin-top: -12px;
	}

	.field-presentation-scale-min-text.error, .field-presentation-scale-max-text.error {
		.Input__wrapper {    
			border-color:$ui-red;
			border-width: 1px;
			border-style: solid;    
		}

		.error-container {
			font-size: 12px;
			text-align: left;
			color: #bc1c0e;
			width: 100%;
			display: block;
			margin-top: 0px;
			margin-bottom: -18px;		
		}
	}
}
