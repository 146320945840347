@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.SubSurveyFormComponent.form-row{
	span.orange {
		color: $yellow-800;
	}

	.field-subsurvey-others {		
		.Input__wrapper {
			max-width: 150px !important;
    		min-width: 150px !important;
		}
	}

	.field-subsurvey-media-types {
		flex-grow: inherit !important;
	}

	.form-row-left.align-top {
		align-items: flex-start !important;
	}

}