@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';

ul.introHomeItems {
    padding-left: 30px;
	.link {
		color: $brand-darkorange;
		font-weight: bold;
		cursor: pointer;
	}
}
