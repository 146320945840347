@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../css/app";

.top-menu-dropdown{
	position: absolute;    
	box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0px 0px;
    list-style: none;
    background-color: #fff;
    border-radius: 4px;
    border-width: 10px;
	margin-top: 28px;
    display: none;	
    border: 1px solid $grey-300;
	li + li {
		border-top: 1px solid $grey-300;
   }

   .submenu-items {
		padding: 10px;
		a {
			color: $grey-700;
    		text-decoration: none;
            white-space:nowrap;
		}
   }
}

.top-menu-dropdown.show {
	display: block;
}

.submenu-link {
	display: inline-block;
    position: relative;
    z-index: 1;
    padding: 10px;
    margin: -10px;
    width: 100%;
}

.submenu-link:focus {
	box-shadow: none;
}