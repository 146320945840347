@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabProgramSelectionComponent{

	.tab-program-selection-form-row {
        margin: 0 0 30px 0;
        display: flex;
        gap: 50px;
        &:last-child{
            margin-bottom:0px;
        }
        .tab-program-selection-form-row-left, .tab-program-selection-form-row-right, .tab-program-selection-form-row-50{
            flex: 1 0 45%;            
            gap:30px;
            > * {
                //flex: 0 0 auto;
            }

            > * > .default-height {
                height:40px;
                display:flex;
                align-items: center;
            }

            > * > .centered {
                text-align:center;
                display:flex;
                justify-content: center;
                
            }
        }

        .tab-program-selection-form-row-50{
            display: flex;
            flex-direction: column;
            gap: 20px;
            min-width: 300px;
            flex: 0 1 45%;
            justify-content: flex-start;
            > * {
                width:100%;
                //flex: 0 0 auto;
            }
            
        }  
		
		.multi-select-component {
			width: 100%;
		}

		.channels-selection {
			padding-top: 20px;
		}

		.broadcasters-selection {
			padding-top: 20px;
		}

		.program-titles {
			margin-top: 20px;
		}
	}
}