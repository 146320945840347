@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.InDepthForm .GlobalControlLineComponent{
    display:flex;
    justify-content: space-between;
    align-items: center;
    gap:50px;

    .name-field{
        flex: 1 0 300px;
        font-size: $h1;
        max-width:500px;
        height:54px;

        .value-display{
            padding:3px 0px;
        }

        .InputComponent{
            font-size: $h1;
            height:54px;
        }

        .Input__wrapper{
            height:54px;
        
            .Input__field{
                font-size: $h1;
            }
        }
    }

    .contol-buttons{
        flex: 0 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 20px;
    }
}