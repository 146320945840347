@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.QuestionSearchComponent {
	.QuestionListContainer {
		height: 400px;		
		overflow: auto;
	}

	.QuestionListContainerScrollbox {
		margin-top: 10px;
	}	
    
	.QuestionListIndepth {
		.column-code{
			width: 20%;
		}

		.column-type {
			width: 10%;
			white-space: nowrap;
		}

		.column-question-text {
			width: 62%;
		}

		.column-add {
			width: auto;		
			text-align:center;
			white-space: nowrap;
		}	
	}

	.title {
		font-size: $h4;		
		.orange {
			color: $yellow-800
		}
	}

	.QuestionItemColumn {
		cursor: pointer;
	}	
}