@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../css/app";

.TabInputComponent{
    .form-row{
        .field-maxlength, .field-min-value , .field-max-value{
            flex: 0 0 100px;
        }

        .field-placeholder{
            flex: 0 0 300px;
        }

        .field-fieldprep{
            flex: 0 0 200px;
        }

        .field-lineslider{
            flex: 0 0 400px;

            &.field-setting-SINGLE .field-min-max,&.field-setting-PREPARED .field-min-max{
                    display:none;
            }
            
            &.field-setting-CUSTOM .field-amount{
                    display:none;
            }

            .SliderTrack-0 .TrackLine{
                background-color: $brand-lightorange;
            }

        }
        .optionalTextRow {
            align-items: center;
        }

        input#optionalText {
            min-width: 250px;
        }
    }

	.field-min-value, .field-max-value {
		.error-container {
			display: none;
		}
	}
	.field-min-value.error, .field-max-value.error {
		.Input__wrapper {    
			border-color:$ui-red;
			border-width: 1px;
			border-style: solid;    
		}

		.error-container {
			font-size: 12px;
			text-align: left;
			color: #bc1c0e;
			width: 100%;
			display: block;
			margin-top: 0px;
			margin-bottom: -18px;		
		}
	}

}