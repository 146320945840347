@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../css/app";

.SideBarComponent{
    flex:0 0 auto;
    width:360px;
    display:flex;
    flex-direction: column;
    border-right: 1px $stroke-light solid;
    transition: width 300ms linear;
    border-collapse: collapse;
    height: calc(100vh - 77px);
    

    .SideBarHeadComponent {
        opacity:1;
        flex-grow:0 1 auto;
        transition: opacity 100ms linear 300ms;
    }

    .main{
        opacity:1;
        flex:1 0 0;
        overflow-y:auto;
        overflow-x:hidden;
        transition: opacity 100ms linear 300ms;
    }

    .foot{
        flex-grow:0 1 auto;
        width:100%;
        height:72px;
        border-top: 1px #DADDE3 solid;
        display:flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 16px 20px 16px 32px;
        margin-right:-1px;
        justify-content: space-between;
        border-collapse: collapse;
        .footer-content{
            flex: 0 0 auto;
            opacity:1;
            transition: opacity 100ms linear 300ms;
            .archive-switch{
                padding-left:0px;
            }
        }
        .sidebar-toggle{
            color: $text-secondary-interactive;
            flex: 0 0 24px;
            cursor: pointer;
        }
    }
}


.SideBarComponent.collapsed{
    //flex:0 0 0px;
    bottom:0px;
    left:0px;
    width:0px;
    transition: width 300ms linear 100ms;
    animation: rem-right-border-delayed 1ms;
    animation-delay: 400ms;
    animation-fill-mode: forwards;
    .SideBarHeadComponent {
        opacity:0;
        //padding:0;
        transition: opacity 100ms linear;
    
    }

    .main{
        opacity:0;
        transition: opacity 100ms linear;
        
    }

    .foot{
        overflow-x: visible;
        width:56px;
        transition: width 300ms linear 100ms;
        z-index:1000;
        animation: add-right-border-delayed 1ms;
        animation-delay: 400ms;
        animation-fill-mode: forwards;
        padding: 16px 16px 16px 16px;
        background-color: $background-light;
        .footer-content {
            opacity:0;
            transition: opacity 100ms linear;
        }
    }
}



@keyframes add-right-border-delayed {
    from {
      border-right: none;
    }
    to {
        border-right:1px $stroke-light solid;
    }
}

@keyframes rem-right-border-delayed {
    from {
        border-right:1px $stroke-light solid;
    }
    to {
      border-right: none;
    }
    
}

.SideBarHeadComponent{
    padding: 10px 20px 20px 32px;
    .title{
        height:83px;
        display:flex;
        justify-content: space-between;
        align-items: center;
        font-size: $h1;
        .button{
            flex: 0 0 auto;
        }
    }
    .toolbar{
        padding-bottom:20px;
        > *{
            display: flex;
            color: $brand-brightorange;
            gap:20px;
        }
        &:empty{
            display:none;
        }
    }

    
    .search {
        /*svg{
            color: $text-inactive
        }*/

        .Input__wrapper{
            border:none;
        }

        /*.InputComponent{
            border:none;
        }*/
    }

}