@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.MySavedFilterComponent {
    max-width: 1000px;
    .mySelectionButton {
        margin-bottom: 20px;
    }

    div.clearAllButton {
        margin-left: -440px;
        margin-bottom: 15px;
    }    

    .mySelectionsBox {
        background-color: #ffffff;
        padding: 15px;     
        margin-top: -48px;
        margin-bottom: 20px !important;
        max-width: 1000px;

        .selections-wrapper {
            align-self: flex-start;
            max-width: min-content;
            
            .field-my-selections {
                max-width: min-content;
                
                .selectfilters {
                    width: 350px;
                }
            }
        }        
    }    

    .edit-mode {
        flex-grow: 1;
        
        .field-my-saved-name {
            align-self: flex-start;
            flex-grow: 1;
        }
    }

    .contol-buttons{
        flex: 0 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
		align-self: flex-end;
    }
}