.ToastMessage {	
	.ToastMessage__Content {
		width: unset !important; 
		max-width: 500px !important;
		min-width: 368px !important;
	}

	.InlineAlert__Message {
		word-break: keep-all !important;
	}	
}