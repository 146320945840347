@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.TabRoleComponent{
	.role-selection {
		max-width: 500px;
		min-width: 500px;
	}

   	.role-selection{
		.admin-user-msg {
			font-weight: 100;
   		}
	}
}