@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.image-preview {
	margin-top: 10px;	
}

.modal-image {
	height:100%; 
	width:auto; 
	object-fit: cover; 
	max-height: 600px; 
	max-width: 1400px
}