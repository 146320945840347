@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";
.multi-select-component {
	min-width: 200px;	
	.selected-item-badge {
		display: inline-block;
		color: #6f7582;
		background-color: transparent;
		border: 1px solid #6f7582;
		margin-right: 10px;
    	margin-top: 10px;

		.badge-content {
			display: flex;
  			align-items: center;
  			justify-content: center;
		}
	}

	.Button--small {
		padding: 0px;
		
		.Button__icon {
			margin-right: 0px;
			margin-left: 8px;
		}
	}

	:focus {
		box-shadow: 0 0 0 0;
	}

	
	.css-1pahdxg-control {
		border-color: $yellow-200 !important;
		box-shadow: 0 0 0 1px $yellow-200 !important;
	}

	.css-1pahdxg-control:hover {
		border-color: $yellow-200 !important;
	}

	.multi-select-box__control--is-disabled {
		background-color: $background-medium;
		border-color: $stroke;
	}
}