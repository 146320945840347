@import '~@ecosystem/ui-lib/core/styles/base/all';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';


.changepasswordWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $background-light;
	padding-top: 100px;
}
.changepasswordForm{
    max-width: 550px;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 2rem;
    background: #ffffff;
}

.form-header{
    text-align: center;
    margin-bottom : 2rem;
}

.form-input{
    background: #fafafa;
    border: 1px solid #eeeeee;
    padding: 12px;
    width: 100%;
}
.form-group{
    margin-bottom: 1rem;
}

.form-button{   
    width: 100%;    
}