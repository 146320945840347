@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "../../../../../css/app";

.TabPermissionComponent{
	.permission-selection {
		max-width: 500px;
		min-width: 500px;
	}

   	.permission-selection{
		.admin-role-msg {
			font-weight: 100;
   		}
	}
}