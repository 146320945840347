*{
    box-sizing: border-box;
}

body, .root, .App{
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: $body-baseline;
    font-family: $font-primary;
}


.app-body{
    flex:1 0 0;
    min-height:0px;
    display:flex;
    flex-direction: row;
}

.app-content {
    padding: 25px 32px 0px 20px;
    flex:1 0 920px;
	height: calc(100vh - 77px);
}




