@import '~@ecosystem/ui-lib/core/styles/base/fonts';
@import '~@ecosystem/ui-lib/core/styles/themes/ecosystem-default/colors';
@import "app/css/app";

.modal {
    display: block;
    position: fixed;
    z-index: 1000000000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    border-radius: 4px;   
     
    .modal-content {
        background-color: $grey-100;
        margin: 10% auto; /* 10% from the top and centered */
        padding: 20px;
        border-radius: 8px !important;
        border: 1px solid #888;
        width: 80%;
        //max-width: 700px;
		max-height: 750;
		// overflow: auto;

		&.small {
			max-width: 700px;
		}

		&.medium {
			max-width: 1200px;
		}

		&.fit-content {
			max-width: fit-content;
		}

		&.large {
			display: flex;
			margin: 10px auto;			
			max-height: 95%;
    		height: 95%;
		}
		&.image-preview {
			max-width: '800px';
      		max-height: '600px';
      		width: 'auto';
      		height: 'auto';
      		margin: 'auto';
		}			
    }
  
    .close {
        color: #aaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .contol-buttons{
        flex: 0 0 auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 20px;
        margin-top: 20px;
		align-self: flex-end;
    }
  }
  
 